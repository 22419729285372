export enum HeatingSystem {
    "Biomass boiler" = "Biomass boiler",
    "District/ communal heating" = "District/ communal heating",
    "Electric heating (not heat pump)" = "Electric heating (not heat pump)",
    "Heat pump (air-source)" = "Heat pump (air-source)",
    "Heat pump (ground-source)" = "Heat pump (ground-source)",
    "Heating oil; boiler over 10 yrs old" = "Heating oil; boiler over 10 yrs old",
    "Heating oil; boiler under 10 yrs old" = "Heating oil; boiler under 10 yrs old",
    "Mains gas or LPG; boiler over 10 yrs old" = "Mains gas or LPG; boiler over 10 yrs old",
    "Mains gas or LPG; boiler under 10 yrs old" = "Mains gas or LPG; boiler under 10 yrs old",
  }

export enum AgeBand {
    "Before 1900" = "Before 1900",
    "1900-1929" = "1900-1929",
    "1930-1949" = "1930-1949",
    "1950-1966" = "1950-1966",
    "1967-1975" = "1967-1975",
    "1976-1982" = "1976-1982",
    "1983-1990" = "1983-1990",
    "1991-1995" = "1991-1995",
    "1996-2002" = "1996-2002",
    "2003-2006" = "2003-2006",
    "2007-2011" = "2007-2011",
    "2012 onwards" = "2012 onwards",
  }

export enum PropertyType {
    House = "House",
    FlatOrMaisonette = "Flat/Masionette",
    ParkHome = "Park home",
    Bungalow = "Bungalow",
  }

export enum WallsShared {
    Detached = "Detached",
    SemiDetached = "Semi-detached",
    SemiDetachedOrEndTerrace = "Semi-detached/End-terrace",
    MidTerrace = "Mid-terrace",
    EndTerrace = "End-terrace",
    EnclosedEndTerrace = "Enclosed end-terrace",
    EnclosedMidTerrace = "Enclosed mid-terrace",
  }

export enum WallConstruction {
    "Granite or whinstone" = "Granite or whinstone",
    "Sandstone or limestone" = "Sandstone or limestone",
    "Solid brick" = "Solid brick",
    "Cavity" = "Cavity",
    "Timber Frame" = "Timber Frame",
    "System built" = "System built",
    "Cob wall" = "Cob wall",
    "Park home wall" = "Park home wall"
  }

export enum GlazingType {
    "Double Glazing < 2002PVC frame 12mm gap" = "Double Glazing < 2002PVC frame 12mm gap",
    "Double Glazing < 2002PVC frame 16+mm gap" = "Double Glazing < 2002PVC frame 16+mm gap",
    "Double Glazing < 2002PVC frame 6mm gap / non-PVC" = "Double Glazing < 2002PVC frame 6mm gap / non-PVC",
    "Double Glazing >= 2002" = "Double Glazing >= 2002",
    "Single Glazing" = "Single Glazing",
    "Triple Glazing" = "Triple Glazing",
    "Secondary Glazing" = "Secondary Glazing",
  }

export enum FloorDescription {
    "Unknown" = "Unknown",
    "As-built" = "As-built",
    "Retro-fitted" = "Retro-fitted"
  }

export enum RoofInsulationThickness {
  NI = "Unknown",
  Zero = "None",
  TwelveMM = "12mm",
  TwentyFiveMM = "25mm",
  FiftyMM = "50mm",
  SeventyFiveMM = "75mm",
  OneHundredMM = "100mm",
  OneHundredFiftyMM = "150mm",
  TwoHundredMM = "200mm",
  TwoHundredFiftyMM = "250mm",
  TwoHundredSeventyMM = "270mm",
  ThreeHundredMM = "300mm",
  ThreeHundredFiftyMM = "350mm",
  FourHundredMM = "400mm or more",
  ND = "Not Defined"
}

export interface Form {
  epcId: string;
  addrLine1?: string;
  addrLine2?: string;
    postcode: string;
    propertyType: PropertyType;
    wallsShared: WallsShared;
    ageBand: AgeBand;
    mainHeatingDescription: string;
    floorEnergyEff: string;
    unheatedCorridorLength: string;
    roofDescription: string;
    roofEnergyEff: string;
    mainHeatingDescriptionCont: string;
    roofInsulationThickness: RoofInsulationThickness;
    wallConstruction: WallConstruction;
    wallsEnergyEff: string;
    surveyFloorDescription: FloorDescription;
    surveyWallInsulation: string;
    floorDescription: FloorDescription;
    wallsDescription: string;
    windowDescription: string;
    windowsEnergyEff: string;
    glazingType: GlazingType;
    lowEnergyPercent: number;
    solarPVPanels: number;
    loftInsInstalled: "Yes" | "No";
    hotWaterDescription: string;
    cavityWallInstalled: "Yes" | "No";
    heatLossCorridor: string;
    floorInsInstalled: "Yes" | "No";
    heatingControlsInstalled: "Yes" | "No";
    solarPanelsInstalled: "Yes" | "No";
    propertySize: number;
    heatingSystem: HeatingSystem;
    extensionCount: number;
    numberHabitableRoms: number;
    numberHeatedRooms: number;
    fixedLightingCount: number;
    numberOpenFireplaces: number;
    floorHeight: number;
    mainFuel: string;
  }