import Auth from "@aws-amplify/auth";
import {
  useEffect,
  useState
} from "react";
import {
  RouteComponentProps,
  Redirect,
  useParams,
  useLocation
} from "react-router";
import {
  Box,
  Spacer,
  Button as ChakraButton,
  Spinner,
  useRadioGroup
} from "@chakra-ui/react";
import { useTypedSelector } from "src/redux/useTypeSelector";
import ActionsListPopover from "src/components/actions-list/ActionsListPopover";
import ActionsListTile from "src/components/actions-list/ActionsListTile";
import { useInView } from "react-intersection-observer";
import { useResetCurrentSectionGroup } from "src/hooks/useResetCurrentSectionGroup";
import RadioSwitch from "src/components/shared/RadioSwitch";
import { useFootprint } from "src/hooks/useFootprint";
import { useQuestionsApi } from "src/api/questions/questions.api";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updatePropertyId } from "src/redux/actionCreators";

import { useUsersApi } from "../../api/users/users.api";
import { FullSpace } from "../../components/shared/FullSpace";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { useAuthContext } from "../../hooks/useAuthContext";
import { IntroSection } from "./sections/IntroSection";
import { SavingsSection } from "./sections/SavingsSection";
import theme from "../../components/theme";
import { Wrapper } from "../../components/shared/Wrapper";
import {
  Recommendation,
  RecommendationGeneratorStatus,
  RecommendationStatus
} from "../../api/users/footprint";
import { routes } from "../../constants";
import RecommendationsFootprint from "./RecommendationFootprint";
import OffsettingFootprint from "./OffsettingFootprint";

const OFFSETTING_DISABLED = process.env.REACT_APP_OFFSETTING_DISABLED === "true";

enum Toggles {
  Reduce = "Reduce your carbon",
  Offsetting = "Offsetting",
}

const TOGGLES = Object.values(Toggles);

const FootprintAnonPage: React.FC<RouteComponentProps> = ({ history }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useResetCurrentSectionGroup();
  const { authDataState } = useAuthContext();
  const isAuthenticated = authDataState.authenticated;
  // Property ID from redux
  const property = useTypedSelector(state => state.property);
  const [ getFootprintResponse, getFootprintRequest ] = useUsersApi("GET_ANON_FOOTPRINT");
  const [ recommendationsToAction, setRecommendationsToAction ] = useState<Recommendation[]>([]);
  const radioGroupProps = useRadioGroup({ defaultValue: TOGGLES[ 0 ] });
  // Do we need all this footprint state stuff when anonymous
  const [ footprintState, setFootprintState ] = useFootprint();
  const [ reportCreated, setReportCreated ] = useState(false);
  const [ getRecommendationStatusResponse, getRecommendationStatusRequest ] = useQuestionsApi("GET_RECOMMENDATION_STATUS");
  const propertyId = property.propertyId || Number(queryParams.get("pId"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePropertyId(propertyId));
  }, [ propertyId, dispatch ]);

  const { ref: inViewRef, entry } = useInView({
    threshold: [
      0.1,
      0.25,
      0.5
    ]
  });

  const isActionsInView = entry && entry?.intersectionRatio >= 0.25;

  useEffect(() => {
    setRecommendationsToAction(footprintState.interestedRecommendations ?? []);

    // what is this???

    // if (window.chatbotId !== window.dashboardChatbotId) {
    //   window.location.reload();
    //   return;
    // }
    // mixpanel.track("ir_dashboard");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If no property id found, redirect to the Home Check
    if (propertyId) {
      getFootprintRequest({ propertyId });
    } else history.push(routes.section(1));
  }, [
    propertyId,
    getFootprintRequest,
    history
  ]);

  // Do we need to set the footprint state for an anoymous user??
  useEffect(() => {
    if (!footprintState?.footprintScores && getFootprintResponse.data) {
      const interestedRecommendations = getFootprintResponse.data.data.recommendations.filter(recommendation => recommendation.status === RecommendationStatus.CommunicationPending);

      setFootprintState({
        footprintScores: getFootprintResponse.data.data.footprintScores,
        interestedRecommendations
      });
    }

    if (getFootprintResponse.data?.data.recommendationsStatus.status === RecommendationGeneratorStatus.SUCCESS) {
      setReportCreated(true);
    }
  }, [
    footprintState,
    getFootprintResponse.data,
    setFootprintState
  ]);

  // do we need to set the report state when anonymous?
  useEffect(() => {
    if (reportCreated === false && getRecommendationStatusResponse.data?.data.status === RecommendationGeneratorStatus.SUCCESS) {
      if (propertyId) getFootprintRequest({ propertyId });

      setReportCreated(true);
    }

    if (reportCreated === false && getFootprintResponse.data?.data.recommendationsStatus.status === RecommendationGeneratorStatus.SUCCESS) {
      setReportCreated(true);
    }

    if (reportCreated === false && getFootprintResponse.data && getFootprintResponse.data.data.recommendationsStatus.status === RecommendationGeneratorStatus.CALCULATING) {
      const interval = setInterval(() => {
        getRecommendationStatusRequest({ id: getFootprintResponse.data?.data.recommendationsStatus.id as number });
      }, 7500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    propertyId,
    footprintState,
    getFootprintResponse,
    getRecommendationStatusResponse,
    reportCreated,
    getFootprintRequest,
    getRecommendationStatusRequest
  ]);

  useEffect(() => {
    // If no property exists, redirect to the Home Check
    if (getFootprintResponse.error && getFootprintResponse.error.message === "No property exists.") {
      history.push(routes.section(1));
    }
  });

  // redirect to logeed user to dashabord location - overide history stack, with no back option
  if (isAuthenticated) {
    Auth.signOut();
  }

  if (!getFootprintResponse.data) {
    return (
      <LoadingPage text="Loading Home Check" />
    );
  }

  if (!reportCreated) {
    return (
      <LoadingPage
        text="Loading Home Check"
      />
    );
  }

  return (

    <>

      <IntroSection
        username=""
        completed={getFootprintResponse?.complete}
        isAuthenticated={isAuthenticated}
      />

      <FullSpace
        color="black"
        backgroundColor={theme.colors.gray[ 100 ]}
        position="sticky"
      >
        <FullSpace
          bg="white"
          p={{ base: 0 }}
          zIndex={1}
        >
          <Box
            paddingInline={{ md: theme.spacing[ 6 ] }}
          >
            <SavingsSection
              isLoading={!getFootprintResponse.data?.data}
              {...(getFootprintResponse.data?.data.footprintScores ?? {})}
            />
          </Box>

          {(OFFSETTING_DISABLED || radioGroupProps.value === Toggles.Reduce) && (
            <RecommendationsFootprint
              isAnonUser
              setRecommendationsToAction={setRecommendationsToAction} // not used
              getFootprintResponse={getFootprintResponse}
              recommendationsToAction={recommendationsToAction} // not used
            />
          )}

          {!OFFSETTING_DISABLED && radioGroupProps.value === Toggles.Offsetting && (
            <OffsettingFootprint
              getFootprintResponse={getFootprintResponse}
            />
          )}
        </FullSpace>

        <Spacer h="50px" />

        <Box
          ref={inViewRef}
          w="100%"
        >
          <ActionsListTile
            list={recommendationsToAction}
          />
        </Box>

        {/* Selected actions list */}

        <ActionsListPopover
          list={recommendationsToAction}
          show={Boolean(recommendationsToAction.length && !isActionsInView)}
        />

      </FullSpace>
    </>
  );
};

export default FootprintAnonPage;
